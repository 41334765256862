window.tempDL = JSON.parse(JSON.stringify(RAC.coreDataLayer));

//Function to remove unnecessary items in DL Object
function removeExtras(jsonObj, getStringify) {
    let blacklistKeys = ["hfEcomElig", "event", "gtm.uniqueEventId", "hfCurrentStore", "hfEcomPV", "hfHybWebSessionGUID", "hfLoggedIn", "hfPgType", "twilioMatchUsed", "hfRMSIDs", "sortFilterOptions", "SortOption"];

    function iterate(item) {
        delete jsonObj[item];
    }

    function clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === null || obj[propName] === "null" || obj[propName] === undefined || obj[propName] === "undefined" || obj[propName] === "") {
                consoleLog("Deleting : " + propName);
                delete obj[propName];
            }
        }
        return obj;
    }

    blacklistKeys.forEach(iterate);
    jsonObj = clean(jsonObj);
    if (getStringify) {
        return JSON.stringify(jsonObj);
    }
    return jsonObj;
}

//How To Use: removeExtras(RAC.coreDataLayer, <true|false>)
Dexie.exists('racDb').then(function (exists) {
    if (!exists) {
        consoleLog("------if not exists---------");

        //Create a DB
        let db = new Dexie('racDb');

        //Create a table in DB
        db.version(2).stores({
            visitedPages: "++id, pagepath, visitTime"
        });

        //open DB for adding data
        db.open().catch(function (err) {
            window.console.error("-------Unable to open DB-- " + err.stack || err);
        });

        // Let's add some data to db:
        db.transaction('rw', db.visitedPages, function () {
            let insert_object = {
                pagepath: document.location.href,
                attributeString: removeExtras(tempDL, true),
                visitTime: new Date().getTime()
            };
            consoleLog("----adding record-------");
            db.visitedPages.add(insert_object);
        }).catch(function (err) {
            console.error("--------Failed to add record to table at 1" + err.stack || err);
        });
        //db.close();
    } else {
        consoleLog("--------else DB exists----------");
        //load db

        let db = new Dexie('racDb');

        //open DB for adding data
        db.open().catch(function (err) {
            window.console.error("----Unable to open DB-- " + err.stack || err);
        });
        let previousPage = RAC.coreDataLayer.previousPage;
        if (previousPage == undefined) previousPage = "landingPage"; //Hack to unset undefined value
        consoleLog("-------My Previous page is: " + previousPage);
        if (previousPage.indexOf("rentacenter.com") !== -1 || previousPage.indexOf("oktapreview.com") !== -1 || previousPage.indexOf("localhost") !== -1) {
            consoleLog("-------in If not landing page---------");
            db.transaction('rw', 'visitedPages', function (visitedPages, trans) {
                // Let's add some data to db:
                let insert_object = {
                    pagepath: document.location.href,
                    attributeString: removeExtras(tempDL, true),
                    visitTime: new Date().getTime()
                };
                consoleLog("adding record");
                db.table('visitedPages').add(insert_object);
            }).catch(function (err) {
                console.error("--------Failed to add record to table at 2" + err.stack || err);
            });
            //db.close();
        } else {
            //delete db
            db.delete().then(function () {
                consoleLog("Old DB Deleted");
                db = new Dexie('racDb');
                //Create a table in DB
                db.version(2).stores({
                    visitedPages: "++id, pagepath, visitTime"
                });
                consoleLog("new DB created");
                db.open();
                db.transaction('rw', 'visitedPages', function (visitedPages, trans) {
                    // Let's add some data to db:
                    let insert_object = {
                        pagepath: document.location.href,
                        attributeString: removeExtras(tempDL, true),
                        visitTime: new Date().getTime()
                    };
                    consoleLog("adding record");
                    db.table('visitedPages').add(insert_object);
                });
            }).catch(function (err) {
                console.error("Error: " + err.stack || err);
            });
            //db.close();
        }
    }
});