//Base64 to normal helper functions
function encodeBase64(myString){
    return btoa(myString);
}
function decodeBase64(myString){
    return atob(myString);
}
// Create sessionCokie with user details on WOF Page
$(".js-submit-contact-info").on('click', function() {
    let userFistName = encodeBase64($('#FirstName').val());
    let userLastName = encodeBase64($('#LastName').val());
    let userEmail = encodeBase64($('#emailaddress').val());
    sessionStorage.setItem('infoForChatBot', userFistName + '||' + userLastName + '||' + userEmail);
});

// Update sf_chat_initiate Cookie value to bot on click of chat btn
$(document).on('click', '.embeddedServiceHelpButton .helpButtonEnabled', function () {
    setCookie("sf_chat_initiate","bot",$('#chatScriptsCookieDays').attr('data-value'),"",true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'custom-event',
        'dlCategory' : 'hybris_sfchat_initiated',
        'dlAction': window.chatOffered ? window.chatOffered : 'bot_chat',
        'dlLabel' : window.location.pathname
    });
});

loadScriptOnDemand($('#chatBotScriptsEswUrl').attr('data-value'), function () {
    let sfUserEmail; //should be changed based on context? Logged In
    let sfUserFirstName; //should be changed based on context? Logged In
    let sfUserLastName; //should be changed based on context? Logged In

    if (RAC.coreDataLayer.hfLoggedIn === "Yes") {
        sfUserEmail = $('#customerEmailForChatBot').val() ? $('#customerEmailForChatBot').val() : '';
        sfUserFirstName = $('#customerAgreementName').val() ? $('#customerAgreementName').val().split(' ')[0] : '';
        sfUserLastName = $('#customerAgreementName').val() ? $('#customerAgreementName').val().split(' ')[1] : '';
    } else {
        //Get these value from identity verification and no login
        let infoForChatBot = sessionStorage.getItem('infoForChatBot');
        if(!isNullUndefined(infoForChatBot)){
            let fields = infoForChatBot.split('||');
            sfUserFirstName = decodeBase64(fields[0]);
            sfUserLastName = decodeBase64(fields[1]);
            sfUserEmail = decodeBase64(fields[2]);
        } else {
            sfUserFirstName = '';
            sfUserLastName = '';
            sfUserEmail = '';
        }
    }

    let custSessionId = '', guid = '', storeNum = '', preApproved = false;
    let userStatusLogin = 'No';
    let datalayer;
    let pairs = document.cookie.split(";");
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split("=");
        if ((pair[0] + '').trim() == '_ga') {
            custSessionId = pair[1];
            custSessionId = custSessionId.slice(6);
        }
    }
    if (typeof RAC != 'undefined') {
        guid = RAC.coreDataLayer.hfLeadUniqueId;
        storeNum = RAC.coreDataLayer.storeNumber;
        preApproved = RAC.coreDataLayer.preApproved;
        userStatusLogin = RAC.coreDataLayer.loggedInStatus;
        let dataLength = window.myPagesData;
        if (dataLength && dataLength.length >= 1) {
            consoleLog("----IndexedDB Data Populated----");
            datalayer = JSON.stringify(window.myPagesData);
        } else {
            datalayer = "No Data Gathered";
            consoleLog("-----No IndexedDB Data Gathered:------- ");
        }
    }
    let chatBotSettings = {
        communityURL: $('#chatBotInitCommunityURL').attr('data-value'),
        sfURL: $('#chatBotInitSfURL').attr('data-value'),
        orgId: $('#chatBotInitOrgId').attr('data-value'),
        LACURL: $('#chatBotInitLACURL').attr('data-value'),
        LAURL: $('#chatBotInitLAURL').attr('data-value'),
        depId: $('#chatBotInitDepId').attr('data-value'),
        butId: $('#chatBotInitButId').attr('data-value'),
        // domain: "qa2-www.rentacenter.com", //"test.neuraflash.com", // set according to the hoisting domain
        domain: window.location.host, //"test.neuraflash.com", // set according to the hoisting domain
        firstName: sfUserFirstName, // should be changed based on context? Logged In
        lastName: sfUserLastName, //  should be changed based on context? Logged In
        email: sfUserEmail, //  should be changed based on context? Logged In
        Chat_Initiated_From_URL_RAC__c: document.location.href,
        Hybris_Lead_Data_RAC__c: datalayer,
        WL_Web_Order_Id_RAC__c: guid,
        WL_Customer_Web_Session_Id_RAC__c: custSessionId,
        Store_Number_RAC__c: storeNum,
        WL_Preapproved_Flag_RAC__c: preApproved,
        WL_Logged_In_Status_RAC__c: userStatusLogin,
        botName: $('#chatBotInitBotName').attr('data-value'),
        snapInURL: $('#chatBotInitSnapInURL').attr('data-value'),
        eswLiveAgentDevName: $('#chatBotInitEswLiveAgentDevName').attr('data-value'),
        displayCTA: isTrue($('#chatBotInitDisplayCTA').attr('data-value')) // Show or hide the Einstein Bot Button
    }
    chatBotSettings.snapInURL = chatBotSettings.communityURL + '/resource/NF_einsteinBot/js/SnapInChat.js?' + Date.now();

    let s = document.createElement('script');
    s.setAttribute('src', chatBotSettings.snapInURL);
    s.onload = function () {
        initChat(chatBotSettings);
        $('body').addClass('chat-bot-init');
        console.log('ChatBot Initiated...');
        window.chatOffered = 'bot_chat'
    };
    document.body.appendChild(s);
    window.dataLayer.push({
        'event': 'salesforce-chat-snippet-loaded'
    });
});