//IndexedDB Wrapper

// This works on all devices/browsers, and uses IndexedDBShim as a final fallback
let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB,
    IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction,
    dbName = "racDb",
    storeName = "visitedPages",
    dbVersion = 20; //this line added by Zulfiqar.

function logerr(err) {
    consoleLog(err);
}

function connectDB(f) {
    // Open (or create) the database
    let request = indexedDB.open(dbName, dbVersion);
    request.onerror = logerr;
    request.onsuccess = function () {
        f(request.result);
    }
    request.onupgradeneeded = function (e) {
        //consoleLog("running onupgradeneeded");
        let Db = e.currentTarget.result;//let Db = e.target.result;

        //uncomment if we want to start clean
        //if(Db.objectStoreNames.contains(storeName)) Db.deleteObjectStore("note");

        //Create store
        if (!Db.objectStoreNames.contains(storeName)) {
            let store = Db.createObjectStore(storeName, {keyPath: "id", autoIncrement: true});
            //store.createIndex("NameIndex", ["name.last", "name.first"], { unique: false });
        }
        connectDB(f);
    }
}

function get(id, f) {
    connectDB(function (db) {
        let transaction = db.transaction([storeName], "readonly").objectStore(storeName).get(id);
        transaction.onerror = logerr;
        transaction.onsuccess = function () {
            f(transaction.result ? transaction.result : -1);
        }
    });
}

function getAll(f) {
    connectDB(function (db) {
        let rows = [],
            store = db.transaction([storeName], "readonly").objectStore(storeName);

        if (store.mozGetAll)
            store.mozGetAll().onsuccess = function (e) {
                f(e.target.result);
            };
        else
            store.openCursor().onsuccess = function (e) {
                let cursor = e.target.result;
                if (cursor) {
                    rows.push(cursor.value);
                    cursor.continue();
                } else {
                    //f(rows);
                    window.myPagesData = rows;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'sf-data-collected'
                    });
                    return rows;
                }
            };
    });
}

function up(obj) {//obj with id
    del(obj.id, 'up');
    add(obj, 'up');
}

function add(obj, info) {
    info = typeof info !== 'undefined' ? false : true;
    connectDB(function (db) {
        let transaction = db.transaction([storeName], "readwrite");
        let objectStore = transaction.objectStore(storeName);
        let objectStoreRequest = objectStore.add(obj);
        objectStoreRequest.onerror = logerr;
        objectStoreRequest.onsuccess = function () {
            if (info) {
                consoleLog("Rows has been added");
            } else {
                consoleLog("Rows has been updated");
            }
            console.info(objectStoreRequest.result);
        }
    });
}

function del(id, info) {
    info = typeof info !== 'undefined' ? false : true;
    connectDB(function (db) {
        let transaction = db.transaction([storeName], "readwrite");
        let objectStore = transaction.objectStore(storeName);
        let objectStoreRequest = objectStore.delete(id);
        objectStoreRequest.onerror = logerr;
        objectStoreRequest.onsuccess = function () {
            if (info)
                consoleLog("Rows has been deleted: ", id);
        }
    });
}

//get data
func = function (result) {
    consoleLog(result);
};
/*
//add data
add({word:'one',data:100});
add({word:'two',data:200});
add({word:'three',data:300});
add({word:'seven',data:700});

//edit data
up({word:'five',data:500,id:1});

//delete
del(3);


get(1,func);
getAll(func);
*/
getAll(func);
