//SF Show Chat Button on selected Pages. the script should be loaded on pages where business says chat button should be visibile
try {
    // Use GTM triggers to check if the user is on the correct page to show chat
    // Otherwise salesforce chat elements will only load in background to support persistent chats across pages
    (function() {
        //CSS to initially hide Docusign chat buttons
        //Cancel this if jQuery doesn't exist
        if (!jQuery) {
            console.log('==== SF Chat: jQuery library not found. Chat feature not loaded.');
            return;
        }
        if(isTrue(window.restrictChat)) {
            return;
        }
        // Store/update a cookie value to uniquely identify a chat initiation action (clicking on the Chat button)
        var setChatId = function() {
            document.cookie = "sf_chat_id=" + Date.now() + performance.now() * 1e11;
        };
        // Track chat id for analytics
        jQuery("body").on(
            "click",
            ".embeddedServiceHelpButton .helpButton, .chat-now-btn, #chat-btn, #chat-agent-btn",
            setChatId
        );
        // Set up click listener the new chat buttons which in turn fires a click() on the main chat button
        jQuery("body").on(
            "click",
            ".chat-now-btn, #chat-btn, #chat-agent-btn",
            function() {
                var selector = 'button.uiButton.helpButtonEnabled';
                jQuery(selector).click();
            }
        );
        // Check whether chat elements exist yet and get references to them if they do
        var chatText, chatButton;
        let setChatOffered = false;
        window.checkAgentsOnline = function() {
            chatText = jQuery("#helpButtonSpan > span.message").text();
            chatButton = jQuery(".embeddedServiceHelpButton .helpButton")[0];
            var chatButton2 = chatButton2 || jQuery("#chat-btn")[0];
            var chatButton3 = chatButton3 || jQuery("#chat-agent-btn")[0]; // this button won't exist until help tooltip opens
            agentsOnline = chatText !== "Agent Offline";
            //consoleLog('=== SF chat - checkAgentsOnline - chat text: ',chatText);
            // If chat elements exist then show the button and we're finished
            if (agentsOnline) {
                console.log("checkAgentsOnline Status = " + agentsOnline);
                //consoleLog('=== Test 2 - SF chat - agents online');
                if (isElemExist(chatButton)) {
                    chatButton.style.display = "block";
                    if((window.chatOffered && window.chatOffered.length) && isFalse(setChatOffered)) {
                        consoleLog("window.chatOffered", window.chatOffered);
                        setChatOffered = true;
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'custom-event',
                            'dlCategory' : 'hybris_sfchat_offered',
                            'dlAction': window.chatOffered,
                            'dlLabel' : window.location.pathname
                        });
                    }
                }
                // If survey button is showing, hide it before showing chat button
                // show chat button if element exists and agents online
                var docusignModalElementVisible = jQuery('#docusign.rac-modal:visible').length > 0;
                //consoleLog('=== SF chat - docusignModalElementVisible',docusignModalElementVisible);
                if (docusignModalElementVisible && chatButton2) {
                    // show Docusign chat buttons while hiding conventional button by adding CSS to cover for the button not currently shown, in the help popup
                    var style3 = document.createElement("style");
                    style3.innerHTML = "#chat-btn, #chat-agent-btn { cursor: pointer; display: block; }";
                    document.body.appendChild(style3);
                    chatButton.style.display = "none";
                    chatButton2.style.display = "block";
                    console.log('=== SF chat - showing docusign buttons');
                } else {
                    // hide docusign chat buttons by overriding previously added CSS
                    var style4 = document.createElement("style");
                    style4.innerHTML = "#chat-btn, #chat-agent-btn { display: none; }";
                    document.body.appendChild(style4);
                    //consoleLog('=== SF chat - showing main button');
                    // show conventional chat button
                    if (isElemExist(chatButton)) {
                        chatButton.style.display = "block";
                    }
                }
                // window.clearInterval(chatInterval);
                //consoleLog('=== SF chat - clearing interval');
            } else {
                //Hide the button if button says agent offline
                consoleLog("checkAgentsOnline Status = " + agentsOnline);
                try {
                    chatButton.style.display = "none";
                } catch (e) {
                    consoleLog("Unable to Hide Chat Button");
                }
                //consoleLog('=== - SF chat - Agent Offline');

            }
        };
        // Set an interval to wait for the salesforce chat script to actually load and execute
        var chatInterval = window.setInterval(checkAgentsOnline, 3000); // check every 3 seconds; show button if agents online and hide if offline
        //consoleLog('=== SF chat - checking for agents online');
    })();
} catch (e) {}

// // Clear chat cookie
$(document).on('click', '.closeChatButton, ' +
    '.embeddedServiceSidebarFeature:not(embeddedServiceLiveAgentSidebarFeature) .embeddedServiceSidebarButton, ' +
    '.dialogButtonContainer > button.uiButton', function () {
    $(".embeddedServiceHelpButton .helpButton button .embeddedServiceIcon").show();
    deleteCookie("sf_chat_initiate");
});